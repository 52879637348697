import { Flex, Image, NumberInput, Select } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import BraintTreeDropIn from '../components/BraintTreeDropIn'
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import TcLoader from '../components/TcLoader';

import '../css/paymentpage.css';
import PaymentMethod from '../components/PaymentMethod';
import SuccessMessage from '../components/SuccessMessage';
import ErrorMessage from '../components/ErrorMessage';
import TcButton from '../components/TcButton'
import { calculateFinalAmount } from '../helpers/calculateFees';
import { detectOS } from '../helpers/os';
import { BiDollar } from 'react-icons/bi';

import { useAppContext } from '../context/AppContext';



const PaymentPage = ({ colors, setColors }) => {



    const paymentToken = localStorage.getItem('paymentToken');

    const navigate = useNavigate();

    const { user, isLoading, isAuthenticated, authType, accountNo, setAccountNo } = useAppContext();

    const [fullName, setfullName] = useState('');

    const [address, setAddress] = useState({
        street: '',
        city: '',
        state: '',
        zip: ''
    });

    const [isQuickPay, setIsQuickPay] = useState(authType === 'public');

    const [amount, setAmount] = useState(0);

    const [originalAmount, setOriginalAmount] = useState(0);


    const [imageUrl, setImageUrl] = useState('');

    const [paymentAmount, setPaymentAmount] = useState(0);

    const [totalAmount, setTotalAmount] = useState(0);

    const [errorMsg, setErrorMsg] = useState('');

    const [error, setError] = useState(false);


    const [isLoadingScreen, setIsLoading] = useState(true);

    const [activePaymentMethod, setActivePaymentMethod] = useState('venmo');

    const [paymentOption, setPaymentOption] = useState('Full Payment');

    const [paymentResult, setPaymentResult] = useState({ success: null, message: '' });

    const [os, setOs] = useState(detectOS());

    const [paymentOptions, setPaymentOptions] = useState([]);

    const getPublicUserData = async () => {


        try {
            const response = await axios.post('public/units/getUnit', { accountNo: accountNo, authType: 'public' });

            const { amount, fullName, address, colors, imageUrl } = response.data;

            setAmount(amount);
            setOriginalAmount(amount);
            setfullName(fullName);
            setAddress(address);
            setColors(colors);
            setImageUrl(imageUrl);
            setTimeout(() => {

                setIsLoading(false);

            }, 1500)


        } catch (error) {

            const response = error.response;

            if (response) {
                const status = error.status;



                if (status === 400) {
                    setErrorMsg('Invalid payment token. Please login again.');
                } else {
                    setErrorMsg('There is an issue on our end. Please Try again later.')

                }

            }

            setTimeout(() => {

                setIsLoading(false);

            }, 1500);
        }

    }


    const getPrivateUserData = async (userID) => {

        try {

            const token = localStorage.getItem('Auth0Token');

            const response = await axios.post(`/protected/units/getUnit`,

                {
                    userId: userID,
                    authType: 'private'
                }

                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        "Content-Type": 'application/json'
                    }
                });

            const { amount, fullName, address, colors, imageUrl, paymentToken } = response.data;

            localStorage.setItem('paymentToken', paymentToken);

            setAccountNo(response.data.accountNo);
            setAmount(amount);
            setOriginalAmount(amount);
            setfullName(fullName);
            setAddress(address);
            setColors(colors);
            setImageUrl(imageUrl);

            setTimeout(() => {

                setIsLoading(false);

            }, 1500)



        } catch (error) {
            const response = error.response;

            if (response) {
                const status = error.status;



                if (status === 400) {
                    setErrorMsg('Invalid payment token. Please login again.');
                } else {
                    setErrorMsg('There is an issue on our end. Please Try again later.')

                }

            }

            setTimeout(() => {

                setIsLoading(false);

            }, 1500);
        }


    }


    useEffect(() => {
        setIsQuickPay(authType === 'public');
    }, [authType])

    useEffect(() => {

        console.log(isAuthenticated);

        if (isQuickPay) {
            getPublicUserData()

        } else {

            if (!isLoading) {
                const userId = user.sub;

                getPrivateUserData(userId);
            }


        }
    }, [isLoading, isQuickPay, isAuthenticated])


    useEffect(() => {

        if (activePaymentMethod) {
            setOriginalAmount(paymentAmount);
            let total = calculateFinalAmount(paymentAmount, activePaymentMethod)
            setTotalAmount(total);

        }

        console.log('payment amount', paymentAmount);
        console.log('payment amount type ', typeof paymentAmount);

    }, [paymentAmount, activePaymentMethod])


    useEffect(() => {

        if (paymentOption === 'Full Payment') {

            setPaymentAmount(amount);
        }

    }, [paymentOption, amount])

    useEffect(() => {

        console.log(paymentResult);

    }, [paymentResult])


    const handleValueChange = (e) => {

        const numericValue = parseFloat(e);
        if (!isNaN(numericValue)) {
            setPaymentAmount(numericValue);
        } else {
            setPaymentAmount(0); // Handle invalid value
        }
    }

    return (


        isLoadingScreen ?
            <Flex w={'300px'} h={'300px'} direction={'column'} justify={'center'} align={'center'} style={{ margin: 'auto' }}>

                <TcLoader message={'Retrieving user data...'} />

            </Flex>
            :


            <Flex className='main-container' style={{
                '--primary-color': colors.primary,
                '--contrast-color': colors.contrast
            }} direction={'column'} align={'center'} flex={1}>
                {imageUrl !== null && <Image className='client-logo' src={imageUrl} />}
                <Flex className='user-info' bg={colors.primary} c={'white'}>
                    <Flex>
                        <div style={{ fontSize: '18px' }}>Name: {fullName} </div>
                    </Flex>
                    <Flex>
                        <div style={{ fontSize: '18px' }}>Address: {Object.values(address).join(', ')}</div>
                    </Flex>
                    <Flex>
                        <div style={{ fontSize: '18px' }}> Outstanding Balance: {`$${amount}`} </div>
                    </Flex>
                </Flex>

                {paymentResult.success === null ?
                    <>
                        <Flex className='user-info' p={0} >
                            <Select value={paymentOption} onChange={setPaymentOption} data={['Full Payment', 'Partial Payment', { label: 'Payment Plan (Only for users with created accounts)', value: 'Payment Plan', disabled: authType == 'public' }]} />
                        </Flex>

                        {paymentOption === 'Partial Payment' &&

                            <Flex className='user-info' p={0} style={{ '--primary-color': colors.primary }}>
                                <NumberInput
                                    clampBehavior='strict'
                                    decimalSeparator='.'
                                    min={1}
                                    max={parseFloat(amount)}
                                    decimalScale={2}
                                    value={paymentAmount}
                                    onChange={(e) => handleValueChange(e)}
                                    leftSection={<BiDollar color={colors.contrast} size={'24px'} />}
                                />
                            </Flex>

                        }

                        <Flex className='user-info' c={'white'} p={0}>


                            <Flex className='payment-method-container'>
                                <PaymentMethod
                                    iconName={'venmo'}
                                    buttonColor={colors.primary}
                                    active={activePaymentMethod === 'venmo'}
                                    activeColor={colors.contrast}
                                    action={() => setActivePaymentMethod('venmo')} />

                                <PaymentMethod
                                    iconName={'card'}
                                    buttonColor={colors.primary}
                                    active={activePaymentMethod === 'card'}
                                    activeColor={colors.contrast}
                                    action={() => setActivePaymentMethod('card')} />


                                <PaymentMethod
                                    iconName={'ach'}
                                    buttonColor={colors.primary}
                                    active={activePaymentMethod === 'ach'}
                                    activeColor={colors.contrast}
                                    action={() => setActivePaymentMethod('ach')} />
                                <PaymentMethod
                                    iconName={'paypal'}
                                    buttonColor={colors.primary}
                                    active={activePaymentMethod === 'paypal'}
                                    activeColor={colors.contrast}
                                    action={() => setActivePaymentMethod('paypal')} />



                                {os === 'iOS' ?
                                    <PaymentMethod
                                        iconName={'applepay'}
                                        buttonColor={colors.primary}
                                        active={activePaymentMethod === 'applepay'}
                                        activeColor={colors.contrast}
                                        action={() => setActivePaymentMethod('applepay')} />
                                    :

                                    <PaymentMethod
                                        iconName={'google'}
                                        buttonColor={colors.primary}
                                        active={activePaymentMethod === 'google'}
                                        activeColor={colors.contrast}
                                        action={() => setActivePaymentMethod('google')} />
                                }
                            </Flex>



                        </Flex>

                        <BraintTreeDropIn paymentMethod={activePaymentMethod} amount={totalAmount} setPaymentResult={setPaymentResult} originalAmount={originalAmount} />
                    </> :

                    paymentResult.success ?

                        <Flex className='user-info' m={0} p={0} direction={'column'} align={'flex-end'}>
                            <SuccessMessage width={'100%'} successMsg={paymentResult.message} />
                            <TcButton action={() => window.location.reload()} color={colors.primary} buttonText={'Make another payment'} width={'30%'} />
                        </Flex>


                        :

                        <ErrorMessage width={'500px'} errMsg={paymentResult.message} />
                }


            </Flex>

    )
}

export default PaymentPage
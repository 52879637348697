import React from 'react'
import { Flex, Loader, Text } from '@mantine/core'
const TcLoader = ({ message, size, fontSize }) => {
    return (
        <Flex direction={'column'} align={'center'}>
            <Loader color='#6753db' size={size || 'lg'} />
            <Text style={{ fontSize: fontSize || '20px' }}>
                <i>
                    {message ? message : 'One Moment...'}
                </i>
            </Text>
        </Flex>
    )
}

export default TcLoader
import { Flex, Image, Loader, Text, TextInput } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import TCLogo from '../images/tc-logo.png';
import TcButton from '../components/TcButton';
import axios from '../api/axios';
import ErrorMessage from '../components/ErrorMessage';
import TcLoader from '../components/TcLoader';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../context/AppContext';

const SelectAccount = () => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { navigate, accountNo, setAccountNo } = useAppContext()

    useEffect(() => {

        if (accountNo) {
            navigate('/payment');
        }

    }, [])



    const handleSubmit = async () => {
        setIsLoading(true);
        setError(false);

        try {

            const payload = {
                email: email,
                accountNo: accountNo,
                authType: 'public'

            };

            const response = await axios.post('/auth', payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });




            localStorage.setItem('accountNo', accountNo)

            localStorage.setItem('quickAuthEmail', email);


            setTimeout(() => {
                setIsLoading(false);
                navigate('/payment')
                localStorage.setItem('authType', 'public');

            }, 1000)

        } catch (error) {

            setError(true);
            const response = error.response;

            if (response) {
                const status = response.status;

                if (status === 400) {
                    setErrorText('Invalid Credentials');
                } else {
                    setErrorText('There is an issue on our end. Please Try again later.')
                }
            } else {
                setErrorText('There is an issue on our end. Please Try again later.')
            }

            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
        }

    }


    return (
        <Flex w={'100%'} justify={'flex-start'} align={'center'} direction={'column'} >
            <Image src={TCLogo} maw={'350px'} />
            {isLoading ?
                <TcLoader /> :

                <form onSubmit={handleSubmit}>
                    <Flex mt={'20px'} direction={'column'} align={'center'} justify={'space-around'} h={'300px'}>
                        {error &&
                            <ErrorMessage errMsg={errorText} width={'100%'} />
                        }


                        <TextInput
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            placeholder='Enter your email address'
                            label='Email'
                            w={'300px'} />

                        <TextInput
                            value={accountNo}
                            onChange={(e) => setAccountNo(e.currentTarget.value)}
                            placeholder='Enter your Account Number'
                            label='Account Number'
                            w={'300px'} />

                        <TcButton type={'submit'} isDisabled={!email || !accountNo} buttonText={'Submit'} width={'200px'} color={'#6753db'} />




                    </Flex>
                </form>

            }
        </Flex >
    )
}

export default SelectAccount
import Logo from '../images/tc-logo.png';
import React from 'react'


import { useState, useEffect } from 'react';
import { useAppContext } from '../context/AppContext';

import { Burger, Flex, Image } from '@mantine/core'
import { IoHome, IoLogOut } from 'react-icons/io5';


const Header = () => {



    const { authType, accountNo, setAccountNo, logout, navigate, isLoading, isAuthenticated, opened, toggle } = useAppContext();

    const [isAuth, setIsAuth] = useState(false);


    const handleLogout = () => {

        authType === 'public' ? handleQuickPayLogout() : handleAuth0Logout();

    }

    const handleAuth0Logout = () => {

        logout();

    }

    const handleQuickPayLogout = () => {

        setAccountNo(null);
        navigate('/');
    }

    useEffect(() => {


        if (authType === 'public' && accountNo) {
            setIsAuth(true);
        } else {

            if (!isLoading) {
                isAuthenticated && setIsAuth(true);

            }
        }



    }, [authType, accountNo, isAuthenticated, isLoading])


    return (

        <Flex w={'100%'} h={'100%'} align={'center'} justify={'space-between'}>

            <Image h={'100%'} src={Logo} />



            <Flex justify={'flex-end'}>

                {isAuth &&
                    <IoLogOut className='home-btn' color='#150d5d' size={30} style={{ marginRight: 20 }} onClick={() => handleLogout()} />


                }


                {!isAuthenticated && <IoHome className='home-btn' color='#150d5d' size={30} style={{ marginRight: 20 }} onClick={() => navigate('/')} />}

                {isAuthenticated &&

                    <Burger
                        opened={opened}
                        onClick={toggle}
                        size="md"
                        mr={'20px'}
                    />
                }

            </Flex>






        </Flex>

    )
}

export default Header
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import axios from '../api/axios';
import { Flex, Image, Text, Card, Group, Table, Modal, Tabs, TabsList, TextInput, Checkbox } from '@mantine/core';
import TcLoader from '../components/TcLoader';
import { IoMail } from 'react-icons/io5';
import { MdCall, MdDelete, MdEdit, MdEmail, MdSms } from 'react-icons/md';

import styles from '../css/profile.module.css';
import { useAppContext } from '../context/AppContext';
import { FaEdit, FaPlus } from 'react-icons/fa';
import { BiArrowBack } from 'react-icons/bi';
import { getBraintreeToken } from '../helpers/braintreeHelper';
import { client, dataCollector, hostedFields, } from 'braintree-web';
import TcButton from '../components/TcButton';
import SuccessMessage from '../components/SuccessMessage';
import ErrorMessage from '../components/ErrorMessage';


const Profile = () => {


    const { user, isLoading, colors } = useAppContext();

    const [userData, setUserData] = useState(null);

    const [paymentMethod, setPaymentMethod] = useState({});

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [methodModalOpened, setMethodModalOpened] = useState(false);

    const [methodModalState, setMethodModalState] = useState('view');

    const [clientInstance, setClientInstance] = useState(null);

    const [cardInstance, setCardInstance] = useState(null);

    const [existingMethodToken, setExistingMethodToken] = useState('');

    const [makeDefaultMethod, setMakeDefaultMethod] = useState(false);

    const [apiResult, setApiResult] = useState(null)

    const getUnitProfile = async () => {
        try {
            const token = localStorage.getItem('Auth0Token');


            const response = await axios.get(`/protected/units/getUnitProfile/${user.sub}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            await initiateBraintreeClient();

            setUserData(response.data);

            const methods = response.data.paymentMethods;
            setPaymentMethods(methods);




        } catch (error) {
            console.log(error);
        }



    };

    const initiateBraintreeClient = async () => {


        const braintreeToken = await getBraintreeToken();


        try {
            const clientInstance = await client.create({
                authorization: braintreeToken,
            });

            setClientInstance(clientInstance);


        } catch (error) {
            console.error('Braintree Error:', error);
        }


    }

    useEffect(() => {

        setPaymentMethod(paymentMethods.find(method => method.isDefault == true));

    }, [paymentMethods])

    useEffect(() => {
        (user && !isLoading) && getUnitProfile();
    }, [isLoading, user]);

    useEffect(() => {

        setApiResult(null);
        if (methodModalState == 'view') {
            setMakeDefaultMethod(false);
        }

    }, [methodModalState])


    const tableBody = () => {
        return [

            [<Text fw={500}>Full Name</Text>, <Text>{userData.unitName}</Text>],

            [<Text fw={500}>HomeOwner Email</Text>, <Text>{userData.unitEmail}</Text>],

            [<Text fw={500}>Phone</Text>, <Text>{userData.phoneNumber}</Text>],

            [<Text fw={500}>Property Address</Text>, <Text>{userData.address1}, {userData.city}, {userData.state} {userData.zip} </Text>],

            [<Text fw={500}>Mailing Address</Text>, <Text display={userData.mailAddress ? 'inherit' : 'none'}>{userData.mailAddress}, {userData.mailCity}, {userData.mailState} {userData.mailZip} </Text>],



        ]

    }


    const handleCard = async () => {

        hostedFields.create({
            client: clientInstance,
            styles: {
                'input': {
                    'font-size': '16px'
                },
                'input.invalid': {
                    'color': 'red'
                },
                'input.valid': {
                    'color': 'green'
                }
            },
            fields: {
                number: {
                    container: '#card-number',
                    placeholder: '4111 1111 1111 1111'
                },
                cvv: {
                    container: '#cvv',
                    placeholder: '123'
                },
                expirationDate: {
                    container: '#expiration-date',
                    placeholder: 'MM/YY'
                },
            }
        }).then((fieldsInstance) => {

            setCardInstance(fieldsInstance);
        }).catch((fieldsErr) => {
            console.error(fieldsErr)
        })

    }

    const nextStepIcon = () => {

        const { communication } = userData.nextStep;

        let result = null;

        switch (communication) {

            case 'Call':
                result = <MdCall color='#6753DB' src={userData.picture} style={{ height: 50, width: 50, }} />;
                break;
            case 'SMS':
                result = <MdSms color='#6753DB' src={userData.picture} style={{ height: 50, width: 50, }} />;
                break;
            case 'Email':
                result = <MdEmail color='#6753DB' src={userData.picture} style={{ height: 50, width: 50, }} />;
                break;
            case 'Letter':
                result = <IoMail color='#6753DB' src={userData.picture} style={{ height: 50, width: 50, }} />;
                break;
            case 'Call & Letter':
                result =
                    <>
                        <MdCall color='#6753DB' src={userData.picture} style={{ height: 50, width: 50, marginRight: '5px' }} />
                        <IoMail color='#6753DB' src={userData.picture} style={{ height: 50, width: 50, marginLeft: '5px' }} />
                    </>
                break;

            default:
                result = null;
                break;
        }

        return result;




    }


    const methodView = () => {

        return <Flex
            flex={1}
            p={'10px'}
            direction={'column'}

        >
            <Flex w={'100%'} justify={'flex-end'} mb={'20px'}>

                <FaPlus className='action-icon' size={20} onClick={() => setCreate()} />
                {/*  <TcButton buttonText={'Add'} width={'fit-content'} color={'#150e5e'} /> */}

            </Flex>
            {paymentMethods.map((method, index) =>

                <Card
                    key={index}
                    shadow='md'
                    w={'100%'}
                    radius={'10px'}
                    mb={'15px'}
                    style={{ flexDirection: 'row' }}>

                    <Flex
                        align={'center'}
                        mr={'20px'}
                        w={'20%'}
                        justify={'flex-start'}>
                        <Image src={method.imageUrl} h={30} />
                    </Flex>

                    <Flex
                        w={'20%'}
                        justify={'flex-start'}>
                        <Text>
                            Ending in {method.maskedNumber.slice(-4)}
                        </Text>
                    </Flex>

                    <Flex
                        w={'20%'}
                        justify={'flex-start'}>
                        <Text>
                            {method.expirationDate}
                        </Text>
                    </Flex>
                    <Flex
                        w={'20%'}
                        justify={'flex-start'}>
                        <Text>
                            {method.isDefault ? "Default" : ""}
                        </Text>
                    </Flex>

                    <Flex
                        w={'20%'}
                        justify={'flex-end'}
                        align={'center'} >
                        <MdEdit
                            className='action-icon'
                            size={25}
                            onClick={() => handleEdit(method.token, method.isDefault)}
                        />
                    </Flex>

                    <Flex
                        w={'10%'}
                        justify={'flex-end'}
                        align={'center'} >
                        <MdDelete
                            className='action-icon'
                            size={25}
                        /*   onClick={() => handleEdit(method.token, method.isDefault)} */
                        />
                    </Flex>


                </Card>

            )}

        </Flex>

    }





    const setCreate = async () => {

        setMakeDefaultMethod(false);

        setMethodModalState('create');
        await handleCard();

    }


    const handleEdit = async (methodToken, isDefault) => {


        setExistingMethodToken(methodToken);
        setMakeDefaultMethod(isDefault);

        setMethodModalState('edit');
        await handleCard();




    }

    const methodEditCreate = () => {



        return <Flex
            p={'10px'}
            flex={1}
            direction={'column'}
        >
            <Flex justify={'flex-start'} w={'100%'} >
                <BiArrowBack className='action-icon'
                    size={25}
                    onClick={() => setMethodModalState('view')} />
            </Flex>
            <Flex mt={'20px'} w={'100%'}>
                <Card
                    shadow='md'
                    w={'100%'}
                    radius={'10px'}
                    style={{ flexDirection: 'column' }}>

                    <Flex w={'100%'} direction={'column'} className={styles['payment-fields-container']}>
                        <Flex mt={'10px'} direction={'column'} className={styles['field-container']} w={'100%'} >
                            <label htmlFor='card-number' > Card Number </label>
                            <div style={{ border: '1px solid #150e5e' }} id='card-number' ></div>
                        </Flex>

                        <Flex mt={'10px'} direction={'column'} className={styles['field-container']} w={'100%'} >
                            <label htmlFor='expiration-date' > Exp. Date </label>
                            <div style={{ border: '1px solid #150e5e' }} id='expiration-date' ></div>
                        </Flex>

                        <Flex mt={'10px'} direction={'column'} className={styles['field-container']} w={'100%'} >
                            <label htmlFor='cvv' > CVV </label>
                            <div style={{ border: '1px solid #150e5e' }} id='cvv' ></div>

                        </Flex>
                    </Flex>

                    <Flex w={'100%'} justify={'flex-end'} align={'center'} >
                        <Checkbox
                            mr={'10px'}
                            checked={makeDefaultMethod}
                            onChange={(e) => setMakeDefaultMethod(e.currentTarget.checked)} />
                        <Text>Set as default payment method</Text>
                    </Flex>

                    <Flex w={'100%'} justify={'flex-end'}>
                        <Flex direction={'row'} w={'50%'} justify={'space-between'} >
                            <TcButton buttonText={'Cancel'} width={'48%'} color={'#6753db'} action={handleModalClose} />
                            <TcButton buttonText={methodModalState == 'edit' ? 'Update' : 'Create'} width={'48%'} color={'#150e5e'} action={() => methodModalState == 'edit' ? handleUpdate() : handleCreate()} />
                        </Flex>
                    </Flex>

                    {apiResult &&
                        <Flex w={'100%'} mt={'20px'}>

                            {apiResult.success ?

                                <SuccessMessage successMsg={apiResult.message} width={'100%'} /> :

                                <ErrorMessage errMsg={apiResult.message} width={'100%'} />

                            }

                        </Flex>
                    }


                </Card>

            </Flex>
        </Flex>

    }


    const handleUpdate = async () => {


        try {
            const { nonce } = await cardInstance.tokenize();


            const response = await axios.patch(`/braintree/updatePaymentMethod/`, {
                nonce,
                existingMethodToken,
                makeDefault: makeDefaultMethod
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('Auth0Token')}`
                }
            });

            console.log(response.data);

            setApiResult({
                success: true,
                message: response.data.message
            })

            await getUnitProfile();

            setTimeout(() => {

                setApiResult(null);
                setMethodModalState('view');

            }, 3000)

        } catch (error) {

            let message = '';
            if (error.response) {
                message = error.response.data.error;
            } else {

                message = 'An Error occured on our end. Please try again later.'

            }
            setApiResult({
                success: false,
                message: message
            })


        }


    }

    const handleCreate = async () => {

        try {
            const { nonce } = await cardInstance.tokenize();


            const response = await axios.post(`/braintree/createPaymentMethod/${userData.customerId}`, {
                nonce,
                makeDefault: makeDefaultMethod
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('Auth0Token')}`
                }
            });


            setApiResult({
                success: true,
                message: response.data.message
            })

            await getUnitProfile();

            setTimeout(() => {

                setApiResult(null);
                setMethodModalState('view');

            }, 3000)

        } catch (error) {

            let message = '';
            if (error.response) {
                message = error.response.data.error;
            } else {

                message = 'An Error occured on our end. Please try again later.'

            }
            setApiResult({
                success: false,
                message: message
            })


        }

    }

    const handleModalClose = () => {


        setMethodModalOpened(false);
        setMethodModalState('view');




    }

    return (
        userData ? (
            <Flex w={'100%'} className={styles['profile-container']} >

                <Flex className={styles['data-container']} align={'flex-start'} >



                    <Card shadow='sm' padding={'lg'} radius={'md'} withBorder w={'100%'} h={'fit-content'}>
                        <Card.Section mt={'10px'}>
                            <Flex flex={1} justify={'center'}>
                                <Image src={userData.picture} style={{ height: 100, borderRadius: '50%', width: 100 }} />
                            </Flex>
                        </Card.Section>

                        <Group justify='center' mt={'lg'} style={{ flexDirection: 'column' }}>
                            <Text fw={500}>{userData.email}</Text>
                            <Text c={'dimmed'}>{userData.address1}, {userData.city}, {userData.state} {userData.zip}</Text>
                        </Group>
                    </Card>

                    {paymentMethod &&
                        <Flex className={styles['data-container']} align={'flex-start'} flex={1} w={'100%'} mt={'20px'}  >

                            <Card shadow='sm' padding={'lg'} pt={10} radius={'md'} withBorder w={'100%'} h={'fit-content'}>
                                <Flex w={'100%'} justify={'flex-end'}>
                                    <MdEdit className='action-icon' size={25} style={{ marginBottom: '5px' }} onClick={() => setMethodModalOpened(true)} />
                                </Flex>
                                <Card.Section mt={'10px'}>
                                    <Flex flex={1} justify={'center'}>
                                        <Image src={paymentMethod.imageUrl} />
                                    </Flex>
                                </Card.Section>

                                <Group justify='center' mt={'sm'} style={{ flexDirection: 'column' }}>
                                    <Text fw={500}> {paymentMethod.cardType} Card ending in {paymentMethod.maskedNumber.slice(-4)} </Text>
                                    <Text> Exp. Date:  {paymentMethod.expirationDate}</Text>

                                    <Text c={'dimmed'}> Default Payment Method</Text>
                                </Group>




                            </Card>
                        </Flex>
                    }

                </Flex>

                <Flex className={styles['data-container']}>
                    <Card shadow='sm' padding={'lg'} radius={'md'} withBorder w={'100%'} h={'fit-content'}>
                        <Table data={{
                            body: tableBody()
                        }}>
                        </Table>

                    </Card>

                    <Flex className={styles['communication-cards-container']}>

                        <Card mt={'20px'} shadow='sm' padding={'lg'} radius={'md'} withBorder h={'fit-content'} className={styles['small-card']} >

                            <Card.Section mt={'10px'}>
                                <Flex flex={1} justify={'center'}>
                                    <Text fw={500} size='40px' c={'#6753DB'}>${userData.balance}</Text>
                                </Flex>
                            </Card.Section>

                            <Group mb={'60px'} justify='flex-start' mt={'lg'} style={{ flexDirection: 'column' }} c={'#6753DB'} >
                                <Flex h={50} direction={'column'} align={'center'}>
                                    <Text fw={500} >Outstanding Balance</Text>
                                    <Text c={'dimmed'}>As of 10/9/2024</Text>
                                </Flex>

                            </Group>
                        </Card>

                        <Card className={styles['small-card']} mt={'20px'} shadow='sm' padding={'lg'} radius={'md'} withBorder h={'fit-content'}>

                            <Card.Section mt={'10px'}>
                                <Flex flex={1} justify={'center'} align={'flex-start'} >
                                    {nextStepIcon()}
                                </Flex>
                            </Card.Section>

                            <Group mb={'50px'} justify='flex-start' align='center' mt={'lg'} style={{ flexDirection: 'column' }}>
                                <Flex direction={'column'} align={'center'} >
                                    <Text c={'#6753DB'} fw={500}>Next Communication</Text>
                                    <Text c={'dimmed'}> {userData.nextStep.communication} on {userData.nextStep.runDate}</Text>
                                </Flex>
                            </Group>
                        </Card>


                    </Flex>


                </Flex>

                <Modal
                    withCloseButton
                    opened={methodModalOpened}
                    onClose={() => handleModalClose()}
                    size={'xl'}
                    styles={{
                        body: {
                            backgroundColor: '#F3F9FE'
                        },
                        header: {
                            backgroundColor: '#F3F9FE'
                        }
                    }}
                    title={<Text pl={'10px'} fw={500} c={'#090155'} size='20px'> Payment Methods </Text>}
                >


                    {methodModalState == 'view' ?
                        methodView() :


                        methodEditCreate()




                    }



                </Modal>



            </Flex >
        ) : (
            <Flex flex={1} justify={'center'} align={'center'}>
                <TcLoader message={'Getting User Data'} size={70} fontSize={24} />
            </Flex>
        )
    );
};

export default Profile;

import React, { useEffect, useRef, useState } from 'react';
import axios from '../api/axios';
import { client, dataCollector, hostedFields, venmo, usBankAccount } from 'braintree-web';
import { Button, Checkbox, Flex, Modal, Select, Text, TextInput } from '@mantine/core';
import { googlePayment } from 'braintree-web';
import { paypalCheckout } from 'braintree-web';
import { useAppContext } from '../context/AppContext';


const BraintreeDropIn = ({ paymentMethod, amount, setPaymentResult, originalAmount }) => {

    const [clientInstance, setClientInstance] = useState(null);
    const [cardInstance, setCardInstance] = useState(null);

    const [accountHolder, setAccountHolder] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountType, setAccountType] = useState('Checking');
    const [accountNumber, setAccountNumber] = useState('');

    const [paypalReady, setPayPalReady] = useState(false);

    const [isQuickAuth, setIsQuickAuth] = useState(localStorage.getItem('authType') === 'quick')

    const [isDefault, setIsDefault] = useState(false);

    const { user, accountNo, authType } = useAppContext();

    const getBraintreeToken = async () => {
        try {
            const response = await axios.get('/braintree/token');
            return response.data.token;
        } catch (error) {
            console.error('Error fetching Braintree token:', error);
            throw error; // Ensure errors are propagated
        }
    };


    useEffect(() => {


        const createClient = async () => {
            const token = await getBraintreeToken();

            try {
                const clientInstance = await client.create({
                    authorization: token,
                });

                setClientInstance(clientInstance);




            } catch (error) {
                console.error('Braintree Error:', error);
            }
        };

        createClient();


    }, []); // Empty dependency array to run effect only once


    useEffect(() => {

        if (paymentMethod === 'card') {

            handleCard()

        }

    }, [paymentMethod])


    const handleCard = async () => {

        hostedFields.create({
            client: clientInstance,
            styles: {
                'input': {
                    'font-size': '16px'
                },
                'input.invalid': {
                    'color': 'red'
                },
                'input.valid': {
                    'color': 'green'
                }
            },
            fields: {
                number: {
                    container: '#card-number',
                    placeholder: '4111 1111 1111 1111'
                },
                cvv: {
                    container: '#cvv',
                    placeholder: '123'
                },
                expirationDate: {
                    container: '#expiration-date',
                    placeholder: 'MM/YY'
                },
            }
        }).then((fieldsInstance) => {

            setCardInstance(fieldsInstance);
        }).catch((fieldsErr) => {
            console.error(fieldsErr)
        })

    }

    const handleVenmo = async () => {

        const token = await getBraintreeToken();

        if (!token) {

            console.log('No token');
            return
        }


        try {
            const venmoInstance = await venmo.create({ client: clientInstance, allowDesktop: true, mobileWebFallBack: true });

            const result = await venmoInstance.tokenize();


            return result.nonce;

        } catch (error) {
            console.error(error)
        }




    };

    const handleCardPayment = async () => {


        try {
            const { nonce } = await cardInstance.tokenize();
            return nonce;

        } catch (error) {
            console.log(error);
        }


    }


    const handleAch = async () => {

        try {
            const achInstance = await usBankAccount.create({ client: clientInstance });

            const bankDetails = {
                accountNumber: accountNumber,
                routingNumber: routingNumber,
                accountType: accountType,
                ownershipType: 'personal',
                /*  billingAddress: {
                     streetAddress: '',
                     extendedAddress: '',
                     locality: '',
                     region: '',
                     postalCode: ''
                 } */
            };

            const result = await achInstance.tokenize({
                bankDetails: bankDetails,
                mandateText: 'By clicking ["Checkout"], I authorize Braintree, a service of PayPal, on behalf of [your business name here] (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.'
            })


            console.log(result);

        } catch (error) {
            console.log(error);
        }

    }


    const handleGooglePay = async () => {
        const googlePayScript = document.createElement('script');
        googlePayScript.src = 'https://pay.google.com/gp/p/js/pay.js';

        var paymentsClient = new window.google.payments.api.PaymentsClient({
            environment: 'TEST' // Or 'PRODUCTION'
        });

        try {
            const googleInstance = await googlePayment.create({
                client: clientInstance,
                googlePayVersion: 2,
                /*  googleMerchantId:'' , */


            })

            const isReadyToPayPromise = await paymentsClient.isReadyToPay({
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: await googleInstance.createPaymentDataRequest().allowedPaymentMethods,
                existingPaymentMethodRequired: true

            })

            const isReadyToPay = isReadyToPayPromise.result;


            if (isReadyToPay) {
                const paymentDataRequest = await googleInstance.createPaymentDataRequest({
                    transactionInfo: {
                        currencyCode: 'USD',
                        totalPriceStatus: 'FINAL',
                        totalPrice: amount// Your amount
                    }
                });


                const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
                cardPaymentMethod.parameters.billingAddressRequired = true;
                cardPaymentMethod.parameters.billingAddressParameters = {
                    format: 'FULL',
                    phoneNumberRequired: true
                };


                const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);


                const { nonce } = await googleInstance.parseResponse(paymentData);

                return nonce

            }



        } catch (error) {

            console.error(error);
        }





    }

    const handlePayPal = async () => {

        setPayPalReady(true);

        try {
            const instance = await paypalCheckout.create({ client: clientInstance });
            const paypalInstance = await instance.loadPayPalSDK({
                currency: 'USD',
                intent: 'capture'
            });

            window.paypal.Buttons({
                fundingSource: window.paypal.FUNDING.PAYPAL,

                createOrder: function () {
                    return paypalInstance.createPayment({
                        flow: 'checkout',
                        amount: amount,
                        currency: 'USD',
                        intent: 'capture',
                        enableShippingAddress: true,
                        shippingAddressEditable: false,
                        shippingAddressOverride: {
                            recipientName: 'Scruff McGruff',
                            line1: '1234 Main St.',
                            line2: 'Unit 1',
                            city: 'Chicago',
                            countryCode: 'US',
                            postalCode: '60652',
                            state: 'IL',
                            phone: '123.456.7890'
                        }
                    });
                },

                onApprove: function (data, actions) {
                    return paypalInstance.tokenizePayment(data).then(async function (payload) {
                        const { nonce } = payload;

                        try {

                            const collectorInstance = await dataCollector.create({ client: clientInstance });

                            const deviceData = collectorInstance.deviceData;

                            try {

                                const payload = {

                                    accountNo: accountNo,
                                    amount: amount,
                                    nonce: nonce,
                                    deviceData: deviceData,
                                    paymentMethod: paymentMethod,
                                    userEmail: isQuickAuth ? localStorage.getItem('quickAuthEmail') : user.email,
                                    originalAmount: originalAmount,
                                    isDefaultPaymentMethod: isDefault,
                                    userId: authType === 'private' ? user.sub : ''

                                }

                                const response = await axios.post('/braintree/createTransaction', payload, {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });


                                if (response.status === 200) {
                                    console.log(response.data);
                                    setPaymentResult({
                                        message: 'Payment Successful.',
                                        success: true,
                                    })

                                }


                            } catch (error) {


                                setPaymentResult({
                                    message: 'Payment Failed.',
                                    success: false,
                                })
                            }


                        } catch (error) {

                        }
                    });
                },

                onError: function (err) {
                    console.error('PayPal error:', err);
                    // Handle error appropriately, e.g., show a user-friendly message
                }
            }).render('#paypal-button');

            setTimeout(() => {
                const button = document.getElementById('paypal-button').querySelector('div');
                if (button) {
                } else {
                    console.error('PayPal button not found');
                    setPayPalReady(false);
                }
            }, 1000);


        } catch (err) {
            console.error('Error setting up PayPal:', err);
            // Handle initialization error
        }
    };

    const handleSubmitPayment = async () => {

        let nonce = '';




        if (paymentMethod === 'card') {
            nonce = await handleCardPayment();

        } else if (paymentMethod === 'venmo') {
            nonce = await handleVenmo();

        } else if (paymentMethod === 'ach') {

            nonce = await handleAch();

            return 0;
        } else if (paymentMethod === 'google') {

            nonce = await handleGooglePay();

        }
        else if (paymentMethod === 'paypal') {

            nonce = await handlePayPal();

            return 0;
        }


        if (!nonce) {
            console.error('Error')

        }


        try {

            const collectorInstance = await dataCollector.create({ client: clientInstance });

            const deviceData = collectorInstance.deviceData;

            try {

                const payload = {
                    accountNo: accountNo,
                    userId: user.sub,
                    amount: amount,
                    nonce: nonce,
                    deviceData: deviceData,
                    paymentMethod: paymentMethod,
                    userEmail: isQuickAuth ? localStorage.getItem('quickAuthEmail') : user.email,
                    originalAmount: originalAmount,
                    isDefaultPaymentMethod: isDefault,
                    userId: authType === 'private' ? user.sub : ''

                }

                console.log(payload);

                const response = await axios.post('/braintree/createTransaction', payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });


                if (response.status === 200) {
                    console.log(response.data);
                    setPaymentResult({
                        message: 'Payment Successful.',
                        success: true,
                    })

                }


            } catch (error) {

                console.log(error);

                setPaymentResult({
                    message: 'Payment Failed.',
                    success: false,
                })
            }


        } catch (error) {

        }




    }

    return (
        <Flex direction={'column'} className='user-info' mt={'10px'} p={0}  >
            {paymentMethod === 'card' ?

                <Flex direction={'column'} className='payment-fields-container' align={'center'} justify={'space-between'} >


                    <Flex direction={'column'} className='field-container'>
                        <label htmlFor='card-number' > Card Number </label>
                        <div id='card-number' ></div>
                    </Flex>
                    <Flex direction={'column'} className='field-container'>
                        <label htmlFor='cvv'   > CVV </label>
                        <div id='cvv' ></div>
                    </Flex>
                    <Flex direction={'column'} className='field-container'>
                        <label htmlFor='expiration-date' > Exp. Date </label>
                        <div id='expiration-date' ></div>
                    </Flex>
                </Flex> : paymentMethod === 'ach' &&

                <Flex className='payment-fields-container' align={'center'} justify={'space-between'} >

                    <Flex direction={'column'} className='ach-account-holder'>

                        <Flex direction={'column'} className='field-container'>
                            <label htmlFor='account-holder'> Account Holder </label>
                            <TextInput
                                value={accountHolder}
                                onChange={(e) => setAccountHolder(e.currentTarget.value)}
                                className='ach-input'
                                placeholder='Enter holder name'
                                id='account-holder' />

                        </Flex>

                        <Flex direction={'column'} className='field-container'>
                            <label htmlFor='account-type'> Account Type </label>
                            <Select
                                value={accountType}
                                onChange={(e) => setAccountType(e)}
                                className='ach-input'
                                data={['Savings', 'Checking']} id='account-type' />
                        </Flex>
                    </Flex>

                    <Flex className='ach-numbers-holder'>
                        <Flex direction={'column'} className='field-container'>
                            <label htmlFor='routing-number'> Routing Number </label>
                            <TextInput
                                value={routingNumber}
                                onChange={(e) => setRoutingNumber(e.currentTarget.value)}
                                className='ach-input'
                                placeholder='Enter routing number'
                                id='routing-number' />
                        </Flex>
                        <Flex direction={'column'} className='field-container'>
                            <label htmlFor='account-number'> Account Number </label>
                            <TextInput
                                value={accountNumber}
                                onChange={(e) => setAccountNumber(e.currentTarget.value)}
                                className='ach-input'
                                placeholder='Enter account number'
                                id='account-number' />
                        </Flex>
                    </Flex>

                </Flex>
            }

            <Flex flex={1} mt={'10px'} style={{ borderRadius: '10px' }} c={'white'} direction={'column'} p={10} align={'flex-start'} bg={'var(--primary-color)'}>

                <Flex>
                    <Text>Payment Method: {paymentMethod.toUpperCase()}</Text>

                </Flex>
                <Text>Total: {amount}</Text>

            </Flex>

            <Flex p={'0'} w={'100%'} direction={'column'} justify={'flex-end'} align={'flex-end'} mt={'20px'}>

                {(authType === 'private' && paymentMethod == 'card') &&
                    <Checkbox value={isDefault} onChange={(e) => setIsDefault(e.currentTarget.checked)} label='Set as default payment method' />

                }

                <Button mt={'10px'} className='pay-btn' bg={'var(--contrast-color)'} onClick={handleSubmitPayment} w={'200px'}  >
                    Pay
                </Button>
            </Flex>

            <Modal opened={paypalReady} onClose={() => setPayPalReady(false)} size={'xl'}>

                <Text size='xl' ta={'center'} w={'100%'}> Proceed with PayPal</Text>

                <div id='paypal-button'>


                </div>
            </Modal>

        </Flex>



    );
};

export default BraintreeDropIn;

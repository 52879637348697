import { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
// 1. Create the App Context
const AppContext = createContext();

// 2. Create AppProvider component
export const AppProvider = ({ children }) => {
    const {
        loginWithRedirect,
        getAccessTokenSilently,
        logout,
        user,
        isAuthenticated,
        isLoading,

    } = useAuth0();

    const [authType, setAuthType] = useState(localStorage.getItem('authType') || null);
    const [accountNo, setAccountNo] = useState(localStorage.getItem('accountNo') || '');
    const [opened, { toggle }] = useDisclosure(false);
    const [activeRoute, setActiveRoute] = useState(localStorage.getItem('activeRoute') || '/');
    const [existingUser, setExistingUser] = useState(false);


    const navigate = useNavigate();

    // Handle navigation based on auth type and authentication status
    const handleAuthType = () => {

        if (activeRoute !== '/auth') {

            if (authType === 'public') {

                toggle(false);

                if (accountNo) {

                    navigate('/payment');
                }

            } else if (authType === 'private') {

                if (!isLoading) {
                    if (!isAuthenticated) {
                        navigate('/');
                    } else {
                        toggle(false);

                        if (!activeRoute.includes('/auth')) {
                            navigate(activeRoute);
                        }
                    }
                }

            } else {

                console.log('no auth selected');
                navigate('/');
            }
        }


    };

    const handleLogout = () => {

        toggle(false);

        console.log('Auth Type at logout', authType);

        if (authType === 'private') {
            logout();
        }



        localStorage.removeItem('accountNo');
        localStorage.removeItem('authType');

        setAccountNo('');
        setAuthType('');



    }

    const handleRouteProtection = (route) => {
        if (route === '/dashboard' || route === '/profile') {
            // Check if the user is authenticated for these protected routes

            //check authType 
            if (authType === 'public') {
                navigate('/'); // Redirect to home page if not authenticated
            } else {
                if (!isAuthenticated && !isLoading) {
                    navigate('/');  // Redirect to login page if not authenticated
                }



            }
        }
    };

    // Run the handleAuthType whenever `authType` or `isLoading` changes
    useEffect(() => {
        handleAuthType();
    }, [authType, isLoading]);

    useEffect(() => {
        handleRouteProtection(activeRoute);
    }, [activeRoute, isAuthenticated, isLoading]);


    useEffect(() => {
        console.log('authType', authType);
    }, [authType])

    useEffect(() => {
        console.log('accountNo', accountNo);
    }, [accountNo])

    const getToken = async (expired) => {

        if (!isLoading && isAuthenticated) {

            let existingToken = localStorage.getItem('Auth0Token');

            if (!existingToken || expired) {
                const token = await getAccessTokenSilently({
                    audience: 'https://dev-m28s5p12c0npbaz8.us.auth0.com/api/v2/',
                    detailedResponse: true
                });


                localStorage.setItem('Auth0Token', token.access_token);


            }
            navigate(activeRoute);



        }
    }

    useEffect(() => {


        getToken(false);

    }, [activeRoute])

    return (
        <AppContext.Provider
            value={{
                loginWithRedirect,
                getAccessTokenSilently,
                logout,
                user,
                isAuthenticated,
                isLoading,
                authType,
                setAuthType,
                accountNo,
                setAccountNo,
                opened,
                toggle,
                navigate,
                activeRoute,
                setActiveRoute,
                handleLogout,
                existingUser,
                setExistingUser,
                getToken
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

// 3. Custom hook to use AppContext
export const useAppContext = () => {
    return useContext(AppContext);
};

import axios from "../api/axios";

export const getBraintreeToken = async () => {
    try {
        const response = await axios.get('/braintree/token');
        return response.data.token;
    } catch (error) {
        console.error('Error fetching Braintree token:', error);
        throw error; // Ensure errors are propagated
    }
};
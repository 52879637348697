import { Text } from '@mantine/core'
import React from 'react'

const ErrorMessage = ({ errMsg, width }) => {

    const styles = {

        'padding': '10px',
        'backgroundColor': 'var(--mantine-color-red-2)',
        'color': 'var(--mantine-color-red-6)',
        'borderRadius': '5px',
    }

    return (
        <Text w={width} ta={'center'} style={styles}>
            {errMsg}
        </Text>
    )
}

export default ErrorMessage
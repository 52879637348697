import axios from "axios";
import { useAppContext } from "../context/AppContext";
// Create an Axios instance
const baseURL = 'https://staging.portal.techcollect.ai/api';
const axiosInstance = axios.create({
    baseURL: baseURL
});

// Function to refresh the token

const getToken = () => {

    return localStorage.getItem('Auth0Token');

}


// Add an interceptor for responses
axiosInstance.interceptors.response.use(
    response => response, // If response is successful, just return it
    async error => {
        const { response } = error;

        // Check if the response status is 401 (Unauthorized)
        if (response && response.status === 401) {
            try {
                const newToken = getToken(); // Refresh the token
                // Set the new token in the headers for the original request
                response.config.headers['Authorization'] = `Bearer ${newToken}`;
                // Retry the original request with the new token
                return axiosInstance(response.config);
            } catch (refreshError) {
                // Handle the error if token refresh fails
                return Promise.reject(refreshError);
            }
        }

        // Check if the response status is 403 (Forbidden)
        if (response && response.status === 403) {
            // Handle 403 errors here, like redirecting or showing a message
            console.error("Access denied. You don't have permission.");
            // Optionally, you can redirect to a login page or show a message
        }

        // For any other error, just reject the promise
        return Promise.reject(error);
    }
);

export default axiosInstance;

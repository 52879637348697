import React, { useEffect, useState } from 'react'
import axios from '../api/axios';
import { useAppContext } from '../context/AppContext';
import { Card, Flex, Pagination, Select, Table, Text, Title } from '@mantine/core';
import { IoDownload } from 'react-icons/io5';
import { FaDownload } from 'react-icons/fa';
import TcLoader from '../components/TcLoader';

const PaymentHistory = () => {

    const [paymentHistory, setPaymentHistory] = useState([]);

    const { user } = useAppContext();

    const [activePage, setActivePage] = useState(1);

    const [rowsNum, setRowsNum] = useState(10);

    const [dataRange, setDataRange] = useState([0, 9]);

    const [paginatedData, setPaginatedData] = useState([]);

    useEffect(() => {


        setDataRange([(activePage - 1) * rowsNum, ((activePage - 1) * rowsNum) + rowsNum]);


    }, [activePage, rowsNum])


    useEffect(() => {

        if (paymentHistory.length > 0) {
            setPaginatedData(paymentHistory.slice(dataRange[0], dataRange[1]));
        }

    }, [dataRange, paymentHistory])


    const downloadReceipt = async (lineItemId, invoiceId) => {


        try {

            const response = await axios.get(`/protected/lineItems/downloadReceipt/${lineItemId}`,

                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('Auth0Token')}`
                    }
                }
            )

            const downloadUrl = response.data.downloadUrl;

            const fileResponse = await axios.get(downloadUrl, {
                responseType: 'blob' // Ensure the response is treated as binary
            });





            const fileURL = window.URL.createObjectURL(new Blob([fileResponse.data]));
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `receipt_.pdf`); // Set the file name for download

            document.body.appendChild(link);
            link.click();

            // Clean up after download
            document.body.removeChild(link);
            window.URL.revokeObjectURL(fileURL);

        } catch (error) {


            console.log(error);
        }


    }


    useEffect(() => {

        const getData = async () => {
            try {

                const response = await axios.get(`/protected/units/getUnitPaymentHistory/${user.sub}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('Auth0Token')}`
                    }
                });


                const data = response.data;

                setTimeout(() => {
                    setPaymentHistory(data.map(item => ([
                        item.invoice_id,
                        item.description,
                        item.notes.split(' ')[0].toUpperCase(),
                        item.fee_amount,
                        item.transaction_charges,
                        (parseFloat(item.fee_amount) + parseFloat(item.transaction_charges)).toFixed(2),
                        item.created_at.split('T')[0],
                        <Flex align={'center'} >
                            <FaDownload size={20} title='Download' className='home-btn' color='#6753DB' onClick={() => downloadReceipt(item.id)} />

                        </Flex >

                    ])))
                }, 1000)



            } catch (error) {

            }
        }

        getData();

    }, [])


    useEffect(() => {

        if (isNaN(rowsNum)) {
            setRowsNum(10);
        }

    }, [rowsNum])


    return (
        paginatedData.length > 0 ?

            <Flex flex={1} p={'lg'} justify={'center'}>


                < Card h={'fit-content'} shadow='lg' w={'100%'} >

                    <Text fw={500} size={'30px'} mb={'20px'} >Payment History</Text>


                    <Select
                        label='Rows per page'
                        value={rowsNum.toString()}
                        data={['10', '25', '50', '100']}
                        onChange={(e) => { setRowsNum(parseInt(e)); }}
                        w={100}
                        m={'10 0'}
                    />

                    <Table
                        h={'fit-content'}
                        data={{
                            head: ['Invoice Id', 'Payment Type', 'Payment Service', 'Amount', 'Transaction Charges', 'Total Paid', 'Date', 'Receipt'],
                            body: paginatedData
                        }}
                    />

                    <Flex
                        flex={1}
                        justify={'space-between'}
                        align={'flex-end'}
                    >

                        <Text>
                            Showing Rows {dataRange[0] + 1} to {dataRange[1] > paymentHistory.length ? paymentHistory.length : dataRange[1]}
                        </Text>

                        <Pagination
                            w={300}
                            color='#6753db'
                            total={parseInt(paymentHistory.length / rowsNum) + 1}
                            boundaries={1}
                            value={activePage} onChange={setActivePage} mt={'sm'} />
                    </Flex>


                </Card >

            </Flex >
            :


            <Flex flex={1} p={'lg'} justify={'center'} align={'center'}>
                <TcLoader size={'50px'} />

            </Flex>


    )
}

export default PaymentHistory
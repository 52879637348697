import { Button, Flex, Text } from '@mantine/core'
import React from 'react'

import styles from '../css/home.module.css';

import { useAppContext } from '../context/AppContext';

const Home = () => {

    const { loginWithRedirect, setAuthType, navigate } = useAppContext()


    const handleAuth0 = () => {


        setAuthType('private');
        localStorage.setItem('authType', 'private');

        loginWithRedirect();

    }

    const handlequickPay = () => {

        setAuthType('public');
        localStorage.setItem('authType', 'public');
        navigate('/quickpay')
    }

    return (
        <Flex className={styles['home-container']} >
            <h1 >Welcome To TechCollect Pay</h1>
            <Text className={styles['text-container']} >
                Choose Your Way to Pay: <br /> <br />

                <strong>Quick Pay: </strong> For a fast and hassle-free experience, select our Quick Pay option. Simply enter your details and complete your payment in seconds.

                <br /> <br />

                <strong>Login:   </strong> Already have an account? Log in to access your payment history, manage your account settings, and enjoy a personalized experience. <br />
            </Text>

            <Flex className={styles['button-container']} >


                <Button color={'#6753db'} variant='outline' className={styles['option-btn']} onClick={() => handlequickPay()}>
                    Quick Pay
                </Button>

                <Button color={'#6753db'} mt={'20px'} className={styles['option-btn']} variant='outline' onClick={() => handleAuth0()}>
                    Login or Create Account <br />

                </Button>
            </Flex>

        </Flex>
    )
}

export default Home
import { Text } from '@mantine/core'
import React from 'react'

const SuccessMessage = ({ successMsg, width, fontSize }) => {

    const styles = {

        'padding': '10px',
        'backgroundColor': 'var(--mantine-color-teal-1)',
        'color': 'var(--mantine-color-teal-9)',
        'borderRadius': '5px',
        'fontSize': fontSize ? fontSize : '16px'

    }

    return (
        <Text w={width} ta={'center'} style={styles}>
            {successMsg}
        </Text>
    )
}

export default SuccessMessage
import React, { useEffect, useState } from 'react'
import { Flex, Text } from '@mantine/core'
import { MdDashboard, MdLogout, MdPayment, MdPayments, MdPerson, } from 'react-icons/md'

import { useAppContext } from '../context/AppContext';

import '../css/sidebar.css';


const Sidebar = ({ colors, setPaymentToken }) => {

    const { logout, navigate, activeRoute, setActiveRoute, handleLogout } = useAppContext();

    const routes = [

        /*  {
             label: 'Dashboard',
             endpoint: '/dashboard',
             icon: <MdDashboard size={25} />
         }, */
        {
            label: 'Profile',
            endpoint: '/profile',
            icon: <MdPerson size={25} />
        },
        {
            label: 'Make a Payment',
            endpoint: '/payment',
            icon: <MdPayment size={25} />
        },
        {
            label: 'Payment History',
            endpoint: '/payment_history',
            icon: <MdPayments size={25} />
        },
    ]


    useEffect(() => {


        navigate(activeRoute);
        localStorage.setItem('activeRoute', activeRoute);

    }, [activeRoute])


    const handleRouteChange = (endpoint) => {

        setActiveRoute(endpoint)

    }



    return (
        <Flex
            w={'100%'}
            h={'100%'}
            p={'md'}
            direction={'column'}
            justify={'space-between'}
            className='sidebar'
            style={{ '--primary': colors.primary, '--contrast': colors.contrast }}>

            <Flex direction={'column'}>
                {routes.map(route =>

                    <Flex
                        key={route.endpoint}
                        className={activeRoute === route.endpoint ? 'sidebar-item-active' : 'sidebar-item'}
                        p={'md'}


                        onClick={() => handleRouteChange(route.endpoint)}>


                        {route.icon}

                        <Text ml={'20px'}>
                            {route.label}
                        </Text>


                    </Flex>

                )}

            </Flex>


            <Flex p={'md'} className='sidebar-item' key={'logout'} onClick={handleLogout}>
                <MdLogout size={25} />
                <Text ml={'20px'}>
                    Logout
                </Text>

            </Flex>



        </Flex>
    )
}

export default Sidebar
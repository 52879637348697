import { Button } from '@mantine/core'
import React, { useEffect, useState } from 'react'

import { IoLogoVenmo } from 'react-icons/io5'
import { BiCreditCard, BiLogoApple } from 'react-icons/bi'
import { BiSolidBank } from 'react-icons/bi'
import { BsGoogle, BsPaypal } from 'react-icons/bs'

const PaymentMethod = ({ iconName, buttonColor, active, action, activeColor }) => {

    const [icon, setIcon] = useState(null);
    const [name, setName] = useState('');



    useEffect(() => {

        switch (iconName) {
            case 'venmo':
                setIcon(<IoLogoVenmo className='payment-method-icon' />);
                setName('Venmo');
                break;
            case 'card':
                setIcon(<BiCreditCard className='payment-method-icon' />);
                setName('Credit Card');
                break;
            case 'ach':
                setIcon(<BiSolidBank className='payment-method-icon' />);
                setName('ACH');
                break;
            case 'applepay':
                setIcon(<BiLogoApple className='payment-method-icon' />);
                setName('ApplePay');
                break;
            case 'google':
                setIcon(<BsGoogle className='payment-method-icon' />);
                setName('GooglePay');
                break;
            case 'paypal':
                setIcon(<BsPaypal className='payment-method-icon' />);
                setName('PayPal');
                break;
            default:
                setIcon(BiCreditCard)

        }

    }, [])




    return (
        <Button style={{ display: 'flex', backgroundColor: active ? activeColor : buttonColor }} className='payment-method' leftSection={icon} onClick={action} >
            {name}
        </Button>
    )
}

export default PaymentMethod
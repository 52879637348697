import { Button } from '@mantine/core'
import React from 'react'
const TcButton = ({ buttonText, width, action, isDisabled, type, color }) => {

    const buttonStyle = {

        'background': color,
        'width': width,
        'marginTop': '20px'
    }

    return (
        <Button maw={'200px'} type={type ? type : 'button'} disabled={isDisabled} style={buttonStyle} onClick={action}>
            {buttonText}
        </Button>
    )
}

export default TcButton
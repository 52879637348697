import './App.css';
import { AppShell, Flex } from '@mantine/core';
import { Routes, Route } from 'react-router-dom';
import SelectAccount from './screens/SelectAccount';

import './App.css'
import PaymentPage from './screens/PaymentPage';
import { useEffect, useState } from 'react';
import Sidebar from './components/Sidebar';
import Login from './screens/Login';
import Header from './components/Header';
import Dashboard from './screens/Dashboard';
import Profile from './screens/Profile';
import Home from './screens/Home';
import PaymentHistory from './screens/PaymentHistory';

import { useAppContext } from './context/AppContext';

function App() {

  const { authType, setAuthType, opened, toggle, navigate, accountNo, setAccountNo, activeRoute } = useAppContext();


  const [colors, setColors] = useState({
    primary: '#090155',
    contrast: '#6753db'
  });



  return (


    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: (opened && authType !== 'public' && !activeRoute.includes('/auth')) ? 300 : 0,
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >


      <AppShell.Header bg={'#f3f9fd'}>
        <Header />
      </AppShell.Header>

      {
        (opened && authType !== 'public' && !activeRoute.includes('/auth')) &&
        <AppShell.Navbar bg={colors.primary}>
          <Sidebar colors={colors} />
        </AppShell.Navbar>
      }

      <AppShell.Main bg={'#f3f9fd'} style={{ overflowY: 'auto' }}>
        <Flex flex={1} h={'100vh'}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/quickpay' element={<SelectAccount />} />
            <Route path='/auth' element={<Login />} />
            <Route path='/payment' element={<PaymentPage colors={colors} setColors={setColors} />} />



            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/payment_history' element={<PaymentHistory />} />
          </Routes>
        </Flex>
      </AppShell.Main>
    </AppShell >









  );
}

export default App;

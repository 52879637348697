import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import '@mantine/core/styles.css';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppProvider } from './context/AppContext';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <MantineProvider>

    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth`,
        audience: 'https://dev-m28s5p12c0npbaz8.us.auth0.com/api/v2/'

      }}

      cacheLocation='localstorage'
    >
      <BrowserRouter>
        <AppProvider>
          <App />
        </AppProvider>
      </BrowserRouter>
    </Auth0Provider>

  </MantineProvider>
);



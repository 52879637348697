const fees = [
    {
        method: "Card",
        feePercentage: 2.59,
        fixedAmount: 0.49
    },
    {
        method: "PayPal",
        feePercentage: 2.59,
        fixedAmount: 0.49
    },
    {
        method: "Venmo",
        feePercentage: 3.49,
        fixedAmount: 0.49
    },
    {
        method: "ACH",
        feePercentage: 0.75,
        fixedAmount: 0,
        cap: 5
    },
    {
        method: "google",
        feePercentage: 0.75,
        fixedAmount: 0,
        cap: 5
    },
];


export const calculateFinalAmount = (amount, paymentMethod) => {


    const foundFee = fees.find((item) => item.method.toLowerCase() === paymentMethod.toLowerCase());

    const feeMultiplier = 1 + foundFee.feePercentage / 100;
    const fixedFee = foundFee.fixedAmount;


    let total = amount * feeMultiplier + fixedFee;

    if (paymentMethod === 'ACH' && (total - amount) > 5) {
        total = amount + 5;
    }


    return total.toFixed(2)
}



import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import TcButton from "../components/TcButton";
import SuccessMessage from '../components/SuccessMessage';
import ErrorMessage from '../components/ErrorMessage';

import { useAppContext } from "../context/AppContext";
import TcLoader from "../components/TcLoader";

const Login = () => {
    const { isAuthenticated, user, getAccessTokenSilently, isLoading, accountNo, setAccountNo, setAuthType, activeRoute, existingUser, setExistingUser, setActiveRoute } = useAppContext();



    const navigate = useNavigate();

    const [result, setResult] = useState(null);
    const [loadingResult, setLoadingResult] = useState(true);


    useEffect(() => {

        setActiveRoute('/auth');
        localStorage.setItem('activeRoute', '/auth');

    }, [])

    const checkIfExistingUser = async (userId) => {
        try {
            const response = await axios.post('/auth', { id: userId, authType: 'private' });
            const result = response.data.result;
            return result;
        } catch (error) {
            console.error("Error checking if user exists:", error);
            return false; // Consider returning a default value
        }
    };

    const addAccountNumber = async (userId, token) => {
        try {
            const response = await axios.post('/protected/units/addAccountNumber', {
                userId: userId,
                accountNo: accountNo
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Ensure token is passed
                }
            });

            setResult(true);
            setActiveRoute('/profile');

            navigate('/profile');

            localStorage.setItem('accountNo', response.data.accountNo);

        } catch (error) {
            console.error("Error adding account number:", error);
            setResult(false);
        }


        setLoadingResult(false);
    };






    useEffect(() => {
        const handleAuth = async () => {
            if (!isLoading && isAuthenticated && user) {
                console.log('User is authenticated, checking existence...');
                const token = await getAccessTokenSilently();
                const isExisting = await checkIfExistingUser(user.sub, token);

                console.log(`isExisting: `, isExisting);

                if (isExisting) {

                    setActiveRoute('/profile');
                    navigate('/profile');

                } else {
                    setExistingUser(isExisting);
                    setLoadingResult(false);
                }



            }
        };

        handleAuth();
    }, [isAuthenticated, isLoading, user]);



    return (
        <Flex justify="center" w="100%">
            {(existingUser === false && loadingResult == false) ?
                <Flex align="center" direction="column">
                    <Text>Welcome to TechCollect, {user?.name}. To proceed, please enter your Account Number.</Text>
                    <br />
                    <TextInput
                        value={accountNo}
                        onChange={(e) => setAccountNo(e.currentTarget.value)}
                        placeholder="Enter your Account Number"
                        label="Account Number"
                        w="300px"
                    />


                    <TcButton buttonText="Continue" action={(e) => {
                        e.preventDefault(); // Prevent form submission if this is inside a form
                        addAccountNumber(user.sub, localStorage.getItem('Auth0Token'));
                    }} />

                    {result === true ?

                        <SuccessMessage successMsg={'Successfully added account number'} /> : result === false ?

                            <ErrorMessage errMsg={'Error Adding Account Number'} /> : <></>

                    }


                </Flex> :

                <TcLoader />
            }
        </Flex>

    );
};

export default Login;
